<template>
  <DocumentTranslationListing 
    :document-translations="documentTranslations" 
    class="mb-2"
  />
</template>

<script>
import { mapState } from 'vuex'
import DocumentTranslationListing from '@/components/documents/DocumentTranslationListing'

export default {
  name: 'DocumentTranslationPage',
  components: {
    DocumentTranslationListing
  },
  computed: {
    ...mapState('documents', ['documentTranslations'])
  }
}
</script>