<template>
  <div>
    <textarea :id="`dit-${editorId}`" :value="content" />
    <p v-if="label" class="caption">{{ label }}</p>
  </div>
</template>
<script>
const API_KEY = '8kpycxhjheir0b2odz6yw1kfsyxo9pm6qtaa0u5temvzej6u'
const CONFIG = {
  'language': 'en',
  'directionality': 'ltr',
  'entity_encoding': 'raw',
  'height': '100%',
  'width': '100%',
  'cleanup_on_startup': true,
  'custom_undo_redo_levels': 20,
  'selector': 'textarea#id_body',
  'theme': 'modern',
  'plugins': 'paste link preview table nonbreaking contextmenu directionality searchreplace wordcount visualchars code fullscreen lists print visualblocks noneditable reference-selector reference-link-selector definitions person-selector phrase-selector',
  'toolbar1': 'fullscreen preview code | bold italic underline | alignleft aligncenter alignright alignjustify | undo redo | removeformat searchreplace paste | print visualblocks',
  'contextmenu': 'reference-selector reference-link-selector reference-link-select reference-link-unselect  definitions unselect-definition person-select person-unselect phrase-select phrase-unselect',
  'paste_as_text': true,
  'menubar': false,
  'statusbar': false,
  'extended_valid_elements': 'reference[id,class],referencelink[id,class],definition[id],phrase[id],person[id]',
  'custom_elements': 'reference,~referencelink,~definition,~phrase,~person',
  'content_css': [
    '/js/tinymce_plugins/reference-link-selector/editor.css',
    '/js/tinymce_plugins/reference-selector/editor.css',
    '/js/tinymce_plugins/definitions/editor.css',
    '/js/tinymce_plugins/person-selector/editor.css',
    '/js/tinymce_plugins/phrase-selector/editor.css'
  ]
}

export default {
  name: 'EditorField',
  components: { },
  props: {
    content: {
      type: String,
      default: null
    },
    editorId: {
      type: String,
      default: null
    },
    height: {
      type: Number,
      default: 200
    },
    label: {
      type: String,
      default: null
    }
  },
  data: () => ({
    editorApiKey: API_KEY,
    editorConfig: CONFIG,
    editorContent: null
  }),
  watch: {
    'editorContent': {
      handler(newValue) {
        this.$emit('update', newValue)
      }
    },
    '$route.params.documentitemtranslationid' () {
      this.tinymce4_init()
    }
  },
  beforeDestroy () {
    tinymce.remove()
  },
  mounted () {
    this.tinymce4_init()
  },
  methods: {
    getContent() {
      return tinymce.get(`#dit-${this.editorId}`).getContent()
    },
    setContent(newContent) {
      tinymce.get(`#dit-${this.editorId}`).setContent(newContent)
    },
    tinymce4_init() {

      const selector = `dit-${this.editorId}`
      const alias = this // eslint-disable-line
      const updateContent = function (evt) {
        alias.editorContent = this.getContent()
      }

      this.editorConfig.selector = `#${selector}`
      this.editorConfig.auto_focus = selector.replace('#','')
      // this.editorConfig.height = this.height
      
      // https://github.com/MaxBernard/l5articles/blob/fdec0763d48ba7f39f0dbd5530480df50777a1dc/resources/assets/js/components/TinyMceComponent.vue
      tinymce.init(this.editorConfig).then((editors) => {
        tinymce.get(selector)
          .on('keyup', updateContent)
          .on('change', updateContent)
          .on('undo', updateContent)
          .on('redo', updateContent)
      })
    }
  }
}
</script>