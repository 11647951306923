<template>
  <v-card tile>
    <v-alert
      v-if="error.show"
      icon="mdi-alert-circle"
      dismissible
      text
      type="error"
    >
      {{ error.message }}
    </v-alert>
    <ImageManager
      v-if="isEnableTranslationImage() && Object.keys(documentTranslationLocal).length && documentTranslationLocal.id"
      :id="documentTranslationLocal.id"
      :connection-type-id="connectionType.documentTranslations"
      :allow-upload="true"
    />

    <v-card-text>
      <ValidationObserver ref="document_translation_form" tag="form">
        <ValidationProvider v-slot="{ errors }" name="title" rules="required|max:255">
          <v-text-field
            v-model="documentTranslationLocal.title" 
            label="Title"
            data-vv-name="title"
            :error-messages="errors"
          />
        </ValidationProvider>
        <v-text-field
          v-model="documentTranslationLocal.subtitle" 
          label="Sub Title"
        />
        <v-row no-gutters>
          <v-col cols="6">
            <v-switch
              v-model="documentTranslationLocal.isdraft"
              label="Draft"
              color="red"
              hide-details
            ></v-switch>
          </v-col>
          <v-col cols="6">
            <ValidationProvider v-slot="{ errors }" name="languageid" rules="required">
              <v-select
                v-model="documentTranslationLocal.languageid"
                :error-messages="errors"
                :items="availableLanguages"
                :item-text="item => item.englishname"
                :item-value="item => item.languageid"
                label="Language"
                required
                data-vv-name="languageid"
                single-line
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <EditorField 
          :editor-id="`description-${String(_uid)}`" 
          :content="documentTranslationLocal.description"
          label="Description"
          @update="updateLocalDocumentTranslation('description', $event)"
        />
      </ValidationObserver>
      <v-card-actions>
        <v-btn @click="save">Save</v-btn>
      </v-card-actions>
    </v-card-text>

    <Debug v-if="debug">
      <template slot="sub-heading">DocumentTranslationForm</template>
      <template slot="info" class="text-xs">
        documentTranslationLocal={{ documentTranslationLocal }}
      </template>
    </Debug>
  </v-card>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { required, max, regex } from 'vee-validate/dist/rules'
import Vue from 'vue'
import configs from '@/configs'

import EditorField from '@/components/shared/EditorField'
import Debug from '@/components/common/Debug'

import ImageManager from '@/components/ImageManager'
import _ from 'lodash'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters'
})

extend('regex', {
  ...regex,
  message: 'Phone number must be valid'
})

export default {
  name: 'DocumentTranslationForm',
  components: {
    ValidationObserver,
    ValidationProvider,
    ImageManager,
    EditorField,
    Debug
  },
  props: {
    documentTranslationId: {
      type: Number
    }
  },
  data() {
    return {
      connectionType: configs.connectionProperties.types,
      saving: false,
      dialogs: {
        delete: false
      },
      error: {
        show: false,
        message: null
      },
      changed: false,
      valid: false,
      documentTranslationLocal: {}
    }
  },
  created() {
    this.loaddata()
  },
  computed: {
    ...mapState('app', ['debug']),
    ...mapState('languages', ['languages']),
    ...mapState('documents', ['documentTranslations']),
    ...mapGetters('documents', ['isEnableTranslationImage']),
    availableLanguages() {
      if (!this.languages) { return }
      
      const items = this.documentTranslations.map((el) => { return el.languageid.languageid })
      const useableLanguages = this.languages.filter((language) => {
        if (this.documentTranslationLocal.languageid && (Number(language.languageid) === Number(this.documentTranslationLocal.languageid.languageid))) { return language }
        if (!items.includes(language.languageid)) { return language }
      })

      return useableLanguages ? useableLanguages : this.languages
    },
    isNewRecord () {
      return !(this.documentTranslationLocal && this.documentTranslationLocal.id)
    }
  },
  methods: {
    ...mapActions('documents', ['createDocumentTranslation', 'updateDocumentTranslation', 'getDocumentTranslations']),
    updateLocalDocumentTranslation (field, value) {
      Vue.set(this.documentTranslationLocal, field, value)
    },
    async save() {
      this.$refs.document_translation_form.validate().then(async (isValid) => {
        if (!isValid) { return }
        this.saving = true

        if (this.isNewRecord) {
          this.documentTranslationLocal.documentid_id = this.$route.params.documentid
          //create new record
          await this.createDocumentTranslation(this.documentTranslationLocal)
          this.$store.dispatch('app/setSnackBar', 'New translation added.')
          this.$router.back(-1)
          return
        }

        await this.updateDocumentTranslation(this.documentTranslationLocal)
        this.$store.dispatch('app/setSnackBar', 'Updated translation.')

        this.$router.back(-1)
      })
    },
    async loaddata() {
      const documentTranslationId = this.documentTranslationId ? this.documentTranslationId : this.$route.params.documenttranslationid

      await this.getDocumentTranslations(this.$route.params.documentid)

      const item = this.documentTranslations.find((v) => Number(v.id) === Number(documentTranslationId))
      this.documentTranslationLocal = _.cloneDeep(item)
      if (!this.documentTranslationLocal) {
        this.initData()
      }
    },
    initData() {
      this.documentTranslationLocal = {}
      this.documentTranslationLocal.documentid = this.$route.params.documentid
    }
  }
}
</script>